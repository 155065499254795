/** @jsx jsx */
import { Flex, jsx, Themed, Grid, Box, ThemeUIStyleObject } from 'theme-ui';
import { FC, Fragment } from 'react';
import { Container } from '@sprinklr/shared-lib';
import { GridProps } from './types';
import RichText from '@sprinklr/shared-lib/components/richText';
import { ITEM_TYPE_VS_RENDERER } from './constants';
import { useGridSettings } from './hooks';
import { CallToAction } from '@sprinklr/shared-lib/components/cta';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';
import { assignDefaultProp } from '@sprinklr/shared-lib/utils/assignDefaultProp';
import { HighlightedText } from '@sprinklr/shared-lib/components/higlightedText';

const GridTemplate: FC<GridProps> = ({
  sectionId,
  headline,
  subHeadline,
  items,
  itemType,
  isOrdered,
  variant,
  mobileGap,
  ctaNoMobileBottomPadding,
  cta,
  topMargin,
  bottomMargin,
  noContainer,
  gridSettingsOverride,
  contentBlockLayout,
  contentBlockAlignment,
  noContainerLeftRightPadding,
  footerNote,
  ...props
}) => {
  const gap = assignDefaultProp(props.gap, 'AUTO');
  const columns = assignDefaultProp(props.columns, 3);
  const maxWidth = assignDefaultProp(props.maxWidth, '1300px');
  const padding = { top: topMargin, bottom: bottomMargin };
  const [pt, pb] = useContainerPadding(padding);
  const GridItem = ITEM_TYPE_VS_RENDERER[itemType];
  const gridSettings = useGridSettings(itemType, columns, gap, mobileGap);
  const outerContainerSx: ThemeUIStyleObject = { pt, pb };
  const innerContainerSx: ThemeUIStyleObject = { maxWidth, px: [0, 0, 0] };
  const noHeadlinesOrCta = !headline && !subHeadline && !cta;
  const ctaOnly = (!headline || !subHeadline) && cta;
  const headlinesOnly = (headline || subHeadline) && !cta;
  const headlineAndCta = headline && subHeadline && cta;

  if (noHeadlinesOrCta) {
    innerContainerSx.py = [0, 0, 0];
  }
  if (ctaOnly) {
    innerContainerSx.pt = [0, 0, 0];
    innerContainerSx.pb = [0, 0, 0];
  }
  if (headlinesOnly) {
    innerContainerSx.pt = [0, 0, 0];
    innerContainerSx.pb = [0, 0, 0];
  }
  if (headlineAndCta) {
    innerContainerSx.pt = [0, 0, 0];
    innerContainerSx.pb = [0, 0, 0];
  }
  if (noContainerLeftRightPadding) {
    outerContainerSx.px = [0, 0, 0];
  }

  const ctaAlignmentMap = {
    CENTER: ['center'],
    LEFT: ['flex-start'],
    RIGHT: ['flex-end'],
    LEFT_ON_DESKTOP: ['center', 'center', 'center', 'flex-start'],
  };
  const ctaAlignment = cta?.alignment;

  const GridTemplateLayout = () => (
    <Fragment>
      {(headline || subHeadline) && (
        <Flex sx={{ flexDirection: 'column', mx: [0, 2, 3, 6], mb: [4, 5, 5] }}>
          {headline ? (
            <HighlightedText as={Themed.h2} boxSx={{ textAlign: 'center' }}>
              {headline}
            </HighlightedText>
          ) : null}

          {subHeadline && (
            <Themed.p sx={{ textAlign: 'center' }}>{subHeadline}</Themed.p>
          )}
        </Flex>
      )}

      <Container containerSx={innerContainerSx}>
        <Grid {...gridSettings} {...gridSettingsOverride}>
          {items?.map((item, index) => {
            item.isOrdered = isOrdered;
            item.order = index + 1;

            const itemTypeArr = [
              'CONTENT_BLOCK',
              'CONTENT_HUB_POST_CARD',
              'MEDIA_CARD',
              'PRODUCT_CARD',
              'CARD',
              'STAT',
              'IMAGEWITHCAPTION',
              'REPORT_CARD',
              'OFFERING_CARD',
              'EVENT_VENUE_CARD',
              'COUNTER_SUMMARY',
            ];

            if (!!!itemTypeArr.includes(itemType)) return;

            if (itemType === 'CONTENT_BLOCK') {
              return (
                <GridItem
                  key={index}
                  {...item}
                  contentBlockSx={{ width: '100%' }}
                  contentAlign={
                    contentBlockAlignment || item.contentAlign || 'CENTER'
                  }
                  contentBlockLayout={contentBlockLayout}
                  isFullBleed={item.mediaType === 'FULLBLEED'}
                />
              );
            }
            if (itemType === 'CONTENT_HUB_POST_CARD') {
              return (
                <GridItem
                  key={index}
                  {...item}
                  itemType={itemType}
                  variant={variant}
                  gap={gap}
                  columns={columns}
                  alwaysShowDescription={false}
                />
              );
            }
            return (
              <GridItem
                key={index}
                item={item}
                itemType={itemType}
                variant={variant}
                gap={gap}
                columns={columns}
              />
            );
          })}
        </Grid>
      </Container>
      {footerNote ? (
        <Flex
          sx={{
            justifyContent: 'center',
            mt: '16px',
            pt: [3, 5, 5],
            strong: {
              color: '#FAA21B',
            },
            p: {
              marginBottom: 0,
              lineHeight: '24px',
            },
            h2: {
              marginBottom: 0,
            },
          }}
        >
          <RichText richTextObject={footerNote} />
        </Flex>
      ) : null}
      {cta && (
        <Flex
          sx={{
            width: '100%',
            alignItems: 'center',
            justifyContent: ctaAlignment
              ? ctaAlignmentMap[ctaAlignment]
              : 'center',
            pt: [4, 5, 5],
            pb: [ctaNoMobileBottomPadding ? 0 : 4, 0, 0],
          }}
        >
          <Box>
            <CallToAction {...cta} />
          </Box>
        </Flex>
      )}
    </Fragment>
  );

  if (noContainer) {
    return <GridTemplateLayout />;
  } else {
    return (
      <Container id={sectionId} containerSx={outerContainerSx}>
        <GridTemplateLayout />
      </Container>
    );
  }
};

GridTemplate.displayName = 'Grid';
export default GridTemplate;
