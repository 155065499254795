import {
  ITEM_TYPE_VS_GRID_SETTINGS,
  ITEM_TYPE_COLUMN_GRID_SETTINGS,
} from './constants';
import { Gap, ItemType } from './types';

const ITEM_TYPES_WITH_OVERRIDES_ENABLED: ItemType[] = [
  'CARD',
  'MEDIA_CARD',
  'STAT',
  'CONTENT_BLOCK',
  'PRODUCT_CARD',
  'REPORT_CARD',
];

const useGridSettings = (
  itemType: ItemType,
  columns: number,
  gap: Gap,
  mobileGap: Gap,
) => {
  if (ITEM_TYPES_WITH_OVERRIDES_ENABLED.includes(itemType)) {
    const colSx = ITEM_TYPE_COLUMN_GRID_SETTINGS[`COLUMNS_${columns}`];
    const gapsPresets = {
      SMALL: 3,
      MEDIUM: 4,
      LARGE: 5,
      MOBILE_MEDIUM: '32px',
    };

    const desktopGridGap = gap !== 'AUTO' ? gapsPresets[gap] : 5;
    const mobileGridGap = mobileGap ? gapsPresets[mobileGap] : desktopGridGap;
    const gridGap = [mobileGridGap, desktopGridGap];

    const gapOverrides =
      gap !== 'AUTO' || !!mobileGap
        ? {
            gridRowGap: gridGap,
            gridColumnGap: gridGap,
          }
        : {};
    return {
      columns: colSx.columns,
      sx: {
        justifyItems: 'center',
        gridRowGap: 5,
        gridColumnGap: [3, 3, 4, 5],
        ...colSx.sx,
        ...gapOverrides,
      },
    };
  } else {
    return ITEM_TYPE_VS_GRID_SETTINGS[itemType];
  }
};

export { useGridSettings };
