/** @jsx jsx */
import { FC } from 'react';
import { Box, Flex, jsx, Themed, useThemeUI } from 'theme-ui';
import { Container, ImageBlock, CallToAction } from '@sprinklr/shared-lib';
import { CTA, Image } from '@sprinklr/shared-lib/@types/entities';
import GridTemplate from '../grid/Grid';

type CaseStudyTemplateProps = {
  headline?: string;
  logo?: Image;
  backgroundImage?: Image;
  mobileBackgroundImage?: Image;
  backgroundOverlayOpacity?: number;
  cards?: [any];
  callToActions?: CTA[];
  cardGrid?: any;
  fullWidth?: boolean;
};

const CaseStudyTemplate: FC<CaseStudyTemplateProps> = ({
  callToActions,
  headline,
  logo,
  backgroundImage,
  mobileBackgroundImage,
  backgroundOverlayOpacity = 30,
  cardGrid,
  fullWidth = true,
}) => {
  const { theme } = useThemeUI();
  const backgroundImageURL =
    backgroundImage && `url(${backgroundImage?.file?.url})`;
  const mobileBackgroundImageURL =
    mobileBackgroundImage && `url(${mobileBackgroundImage?.file?.url})`;

  return (
    <Container
      fullWidth={fullWidth}
      sx={{
        backgroundImage: [
          mobileBackgroundImage ? mobileBackgroundImageURL : backgroundImageURL,
          null,
          backgroundImageURL,
        ],
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        py: [theme.container.sm, null, theme.container.md, theme.container.lg],
        ':before': {
          content: "''",
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: `rgba(0,0,0,${backgroundOverlayOpacity / 100})`,
        },
      }}
    >
      <Container
        sx={{
          position: 'relative',
          zIndex: 1,
          margin: '0 auto',
          py: [4, 4, 4, 4],
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {logo && (
            <ImageBlock
              image={logo}
              altTitle={logo.description}
              imageSx={{
                height: '100%',
                maxHeight: '46px',
                width: '100%',
                mb: '46px',
              }}
            />
          )}
          <Themed.h3
            sx={{
              m: [0],
              color: '#fff',
              textShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
              textAlign: 'center',
            }}
          >
            {headline}
          </Themed.h3>
        </Flex>

        <Flex
          sx={{
            justifyContent: 'center',
            flexWrap: 'wrap',
            pt: [4, null, 5],
          }}
        >
          {cardGrid && (
            <GridTemplate
              {...cardGrid}
              noContainer
              maxWidth={cardGrid.maxWidth ?? '1360px'}
              gridSettingsOverride={{ columns: [1, 1, 1, cardGrid.columns] }}
            />
          )}

          {callToActions ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                pt: 5,
              }}
            >
              {callToActions.map((cta, index) => (
                <Box key={JSON.stringify(cta)} sx={{ ml: index > 0 ? 3 : 0 }}>
                  <CallToAction ctaTextSx={{ lineHeight: '30px' }} {...cta} />
                </Box>
              ))}
            </Box>
          ) : null}
        </Flex>
      </Container>
    </Container>
  );
};

export default CaseStudyTemplate;
